<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/6.png`" alt="">
      <div class="all-title-ani">关于我们</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">用先进技术帮助医疗机构提供高品质、可信赖的医疗服务赋能医疗机构全方位数智化转型，实现高质量可持续发展，帮助医疗机构开源、节流、增效，从优秀到卓越。</div>
    </div>
    <div class="aboutus">
      <div class="company-profile">
        <div class="All-title">企业简介</div>
        <img :src="`${$url}/aboutus/1.png`" alt="">
        <div>
          <div>关于云数智康</div>
          <div class="profile-line"></div>
          <div>多年来，云数智康以提高医疗机构经营效能为己任，提供基于信息化技术及数据价值的智慧医疗整体解决方案，赋能医疗机构全方位数字化转型，实现高质量高发展。</div>
          <div>云数智康医疗立足北京，辐射全国，深耕大健康、人工智能大数据、互联网+智慧医疗等前沿领域，覆盖医疗数字化生态产品 (智慧医院、互联网医疗、医疗大数据、医疗器械等)，通过科技引领、动态赋能、持续创新，始终专注于医疗数字化、医疗智能化的研发与升级，为全国医疗机构提供高品质的产品与服务。</div>
        </div>
      </div>
      <div class="company-advantages">
        <div class="All-title">核心优势</div>
        <div class="All-box">
          <div>
            <div>
              <img :src="`${$url}/internet/1.png`" alt="">
              <img :src="`${$url}/internet/16.png`" alt="">
              <div>应用层</div>
              <div>基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/2.png`" alt="">
              <img :src="`${$url}/internet/17.png`" alt="">
              <div>平台层</div>
              <div>完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/3.png`" alt="">
              <img :src="`${$url}/internet/18.png`" alt="">
              <div>传输层</div>
              <div>借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/4.png`" alt="">
              <img :src="`${$url}/internet/19.png`" alt="">
              <div>感知层</div>
              <div>广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余, 帮助客户有效降低实施成本。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/5.png`" alt="">
              <img :src="`${$url}/internet/20.png`" alt="">
              <div>体验层</div>
              <div>使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/6.png`" alt="">
              <img :src="`${$url}/internet/21.png`" alt="">
              <div>开发层</div>
              <div>告别各类工具的沉重束缚，使用桌面端，移动端访问, 有效降低开发运维的成本和复杂度。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="honorary-qualifications">
        <div class="All-title">荣誉资质</div>
        <div class="swiper-box">
          <div class="swiper-wrapper">
            <div class="swiper-slide my-swiper-slide">
              <div class="honorary-item">
                <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/902.png" alt="">
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="honorary-item">
                <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/9070.png" alt="" style="width: 3.3067rem;">
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="honorary-item">
                <img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/9071.png" alt="" style="width: 5.92rem;">
              </div>
            </div>
            <div class="swiper-slide my-swiper-slide">
              <div class="honorary-item">
                <img  src="../assets/12.jpg" alt=""  style="width: 3.3067rem;">
              </div>
            </div>
          </div>
          <img class="fixed-prev swiper-button-prev " :src="`${$url}/aboutus/3.png`" alt="">
          <img class="fixed-next swiper-button-next" :src="`${$url}/aboutus/4.png`" alt="">
     
        </div>
      </div>
      <div class="aboutus-vision m2cm2a">
        <div class="vision" :class="{'vision-click':vision==1}" @click="setVision(1)">
          <div>
            <img :src="`${$url}/aboutus/5.png`" alt="">
          </div>
          <div>
            <div>愿景</div>
            <div class="m2cmc2p1" style="display: block;">Vision</div>
            <div class="m2cmc2p2" style="display: none;">
              <div></div>
              <div>成为智慧健康服务的创领者！</div>
            </div>
          </div>
        </div>
        <div class="vision" :class="{'vision-click':vision==2}" @click="setVision(2)">
          <div>
            <img :src="`${$url}/aboutus/6.png`" alt="">
          </div>
          <div>
            <div>使命</div>
            <div class="m2cmc2p1" style="display: block;">mission</div>
            <div class="m2cmc2p2" style="display: none;">
              <div>以数智医疗服务，助力医疗与</div>
              <div>大健康行业高质量发展。</div>
            </div>
          </div>
        </div>
        <div class="vision" :class="{'vision-click':vision==3}" @click="setVision(3)">
          <div>
            <img :src="`${$url}/aboutus/7.png`" alt="">
          </div>
          <div>
            <div>价值观</div>
            <div class="m2cmc2p1" style="display: block;">sense of worth</div>
            <div class="m2cmc2p2" style="display: none;">
              <div>客户第一、至诚守信</div>
              <div>务实进取、责任担当</div>
              <div>创新求变、高效执行</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import '../style/swiper.css'
export default {
  components: {
    Header,
    Footer
  },
  methods: {
    setVision(a) {
      if (this.vision !== a) {
        this.vision = a
        $('.vision').find('.m2cmc2p2').slideUp(300)
        $('.vision').find('.m2cmc2p1').slideDown(300)
        this.$nextTick(() => {
          $('.vision-click').find('.m2cmc2p1').slideUp(300)
          $('.vision-click').find('.m2cmc2p2').slideDown(300)
        })
      }
    },
    addisMilepost() {
      if (this.isMilepost == 2) {
        return
      } else {
        this.isMilepost++
      }

      this.mySwiper.slideTo(this.isMilepost)
    },
    noisMilepost() {
      if (this.isMilepost == 0) {
        return
      } else {
        this.isMilepost--
      }
      this.mySwiper.slideTo(this.isMilepost)
    },
    initSwiper() {
      const that = this
      this.mySwiper = new Swiper('.swiper-box',{
        direction: 'horizontal',
        slidesPerView: 1,
        loopFillGroupWithBlank: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        loop: true,
        // 使用分页器
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

      })
    }
  },
  data() {
    return {
      isMilepost: 0,
      mySwiper: null,
      vision: 0
    }
  },
  mounted() {
    this.initSwiper()
  }

}
</script>

<style lang="scss">
.aboutus {
  .company-profile {
    margin: 0 auto;
    background: #f5f6f8;
    padding: 100px 24px 80px;
    img {
      width: 100%;
      margin-top: 48px;
    }
    > div:nth-child(3) {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
      border-radius: 0px 0px 8px 8px;
      opacity: 1;
      padding-left: 26px;
      overflow: hidden;
      > div:nth-child(1) {
        font-size: 34px;
        font-weight: 500;
        color: #181818;
        margin: 32px 0;
      }
      .profile-line {
        width: 96px;
        height: 8px;
        background: #0b61c4;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-bottom: 20px;
      }
      > div:nth-child(3),
      > div:nth-child(4) {
        width: 654px;
        font-size: 28px;
        font-weight: 400;
        color: #181818;
        line-height: 44px;
        margin-bottom: 22px;
      }
    }
  }
  .company-advantages {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/14.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 90px;
    padding-top: 100px;
  }
  .honorary-qualifications {
    padding-top: 100px;
    padding-bottom: 100px;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/aboutus/2.png')
      no-repeat;
    background-size: 100% 100%;
    height: 728px;
    width: 100%;
    position: relative;
    overflow: hidden;
    .All-title {
      margin-bottom: 64px;
    }
    .honorary-item {
      width: 600px;
      height: 400px;
      background: #f5f5f8;
      box-shadow: 0px 16px 24px 2px rgba(3, 19, 52, 0.12);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 362px;
      }
    }
    .fixed-prev {
      position: absolute;
      top: 50%;
      width: 72px;
      left: 38px;
      height: 72px;
      z-index: 99;
      cursor: auto;
      margin-top: 0;
      &:focus {
        outline: none;
      }
    }
    .fixed-next {
      position: absolute;
      top: 50%;
      right: 38px;
      width: 72px;
      height: 72px;
      z-index: 99;
      cursor: auto;
      margin-top: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .aboutus-vision {
    width: 100%;
    padding: 80px 24px 80px;
    background: #f5f6f8;
    overflow: hidden;
    .vision-click {
      > div:nth-child(1) {
        &::after {
          opacity: 0.6;
        }
        img {
          transform: scale(1.05);
        }
      }
    }
    > div {
      position: relative;
      margin-bottom: 32px;
      width: 100%;
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
      > div:nth-child(1) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: #206ac2;
          transition: all 0.5s ease-in-out;
          opacity: 0;
        }
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          transition: all 0.5s ease-in-out;
        }
      }
      > div:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 99;
        > div:nth-child(1) {
          font-size: 44px;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 30px;
        }
        .m2cmc2p1,
        .m2cmc2p2 {
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
          width: 700px;
          > div {
            line-height: 40px;
            width: 700px;
          }
        }
      }
    }
  }
}
</style>
